import React from 'react';
import logo from './logo.svg';
import { AppLink, AppLogo, Header, MainBox } from './App.style';

function App() {
  return (
    <MainBox>
      <Header>
        <AppLogo src={ logo } className="app-logo" alt="logo"/>
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <AppLink
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </AppLink>
      </Header>
    </MainBox>
  );
}

export default App;
