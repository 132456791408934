import styled from '@emotion/styled';
import { Box, Link } from '@mui/material';

export const MainBox = styled(Box)`
  text-align: center;
`;

export const Header = styled.header`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  @media (prefers-reduced-motion: no-preference) {
    .app-logo {
      animation: app-logo-spin infinite 20s linear;
    }
  }

  @keyframes app-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const AppLogo = styled.img`
  height: 40vmin;
  pointer-events: none;
`;

export const AppLink = styled(Link)`
  color: #61dafb;
`
