import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme as MuiTheme } from '@mui/material';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const client = new QueryClient();

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends MuiTheme {
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#122337',
    },
    secondary: {
      main: '#0f91d7',
    },
    tertiary: {
      main: '#edc452',
    },
    background: {
      default: '#f5f5f5',
      paper: '#f5f5f5',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: 'Tungsten, sans-serif',
    h1: {
      fontFamily: 'Tungsten, sans-serif',
      fontWeight: 800,
      fontSize: 40,
      textTransform: 'uppercase',
      color: '#122337',
    },
    h2: {
      fontFamily: 'Tungsten',
      fontWeight: 129,
      fontSize: 36,
      color: '#0F91D7',
    },
    h4: {
      fontFamily: 'Publico, times-new-roman',
      fontWeight: 300,
      fontSize: 16,
      fontStyle: 'italic',
      color: '#122337',
    },
    h5: {
      fontFamily: 'Lato, arial',
      fontWeight: 300,
      fontSize: 13,
      color: '#122337',
    },
    button: {
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif',
          fontWeight: 400,
          height: 32,
          fontSize: 13,
          backgroundColor: '#edc452',
        },
        secondary: {
          background: 'none',
          border: '1px solid #edc452',
          color: '#edc452',
          boxShadow: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Tungsten, sans-serif',
          letterSpacing: '1px',
          fontWeight: 400,
          fontSize: 20,
          height: 45,
          width: '90%',
        },
      },
    },
  },
});
export default theme;

root.render(
  <QueryClientProvider client={client}>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<App />} />
        </Routes>
      </BrowserRouter>
    </MuiThemeProvider>
  </QueryClientProvider>,
);
